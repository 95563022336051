@use 'styles' as theme;
@import '../../../../styles/mixins';

$mainContentWidth: theme.size(70);

.root {
  padding: theme.size(6 0);

  @include theme.mediaDesktopUp {
    padding: theme.size(8 0);
  }
}

.vertical {
  padding-bottom: 0;
}

.subtitle {
  max-width: $mainContentWidth;
}

.container {
  display: grid;
  justify-content: center;
  gap: theme.size(6);

  @include theme.mediaDesktopUp {
    gap: theme.size(4);
    grid-template-columns: minmax($mainContentWidth, 1fr) 1fr;
  }

  .vertical & {
    @include theme.mediaTabletLandscapeUp {
      gap: theme.size(5);
      grid-template-columns: repeat(12, 1fr);
    }
  }
}

.titleWrapper {
  width: 100%;
  text-align: center;

  @include theme.mediaDesktopUp {
    text-align: left;
  }
}

.centered {
  justify-content: center;
  align-items: center;

  @include theme.mediaDesktopUp {
    align-items: flex-start;
  }

  .vertical & {
    @include theme.mediaDesktopUp {
      align-items: center;
    }
  }
}

.imageWrapper {
  position: relative;
  margin-top: auto;
  display: flex;
  max-height: 100%;
}

.leftContent {
  .vertical & {
    @include theme.mediaTabletLandscapeUp {
      grid-column: 4 / 10;
    }
  }
}

.rightContent {
  .vertical & {
    @include theme.mediaPhoneOnly {
      margin: 0 theme.size(-2);
    }

    @include theme.mediaTabletLandscapeUp {
      grid-row-start: 2;
      grid-column: span 12;
    }
  }
}
