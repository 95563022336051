@use 'styles' as theme;
@import '../../../../styles/mixins';

.root {
  background-color: theme.$colorBlue;
  position: relative;
}

.container {
  max-width: theme.size(100);
}

.columns {
  @include theme.mediaDesktopUp {
    max-width: 100%;
    padding: 0;
    display: flex;
  }
}

.subtitle {
  white-space: break-spaces;
}

.content {
  @include sectionVerticalPadding;

  flex: 1;
}

.sidePadding {
  @include theme.mediaDesktopUp {
    padding-right: theme.size(4);
    padding-left: theme.size(4);
  }
}
