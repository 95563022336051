@use 'styles' as theme;
@import '../../styles/colors.module';

.root {
  position: relative;
}

.text {
  z-index: 1;
  position: relative;
}

.block {
  display: inline-block;
}

.curve {
  position: absolute;
  z-index: 0;
  bottom: -25%;
  left: 0;
  color: transparent;
  height: 17px;
  width: 100%;
}

.accent {
  color: theme.$colorYellow;
}

.primary {
  color: theme.$colorBlue500;
}
