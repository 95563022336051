@use 'styles' as theme;

.root {
  align-items: flex-start;
  background-color: theme.$colorWhite;
  border-radius: theme.$borderRadiusXL;
  flex-direction: column;
  padding: theme.size(4 5);

  @include theme.mediaTabletPortraitUp {
    align-items: center;
    flex-direction: row;
  }
}

.illustration {
  @include theme.square(theme.size(10));

  flex-shrink: 0;

  @include theme.mediaTabletLandscapeUp {
    @include theme.square(theme.size(15));
  }
}
