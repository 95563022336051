@use 'styles' as theme;

.root {
  display: flex;
  margin: 0 -#{theme.$framePaddingMobileUp};

  @include theme.mediaTabletLandscapeUp {
    margin: 0 -#{theme.$framePaddingTabletLandscapeUp};
  }

  @include theme.mediaDesktopUp {
    margin: 0;
  }
}

.image {
  width: 100%;
  height: auto;
  max-height: theme.size(53);
  margin: 0 auto;
  text-align: center;
  object-fit: cover;

  @include theme.mediaDesktopUp {
    max-height: 100%;
  }
}
