@use 'styles' as theme;
@import '../../../styles/mixins';

.checklist {
  @include theme.hideUntilDesktop;

  @include theme.mediaDesktopUp {
    @include sectionVerticalPadding('bottom', 'small');
  }
}
