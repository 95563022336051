@use 'styles' as theme;

.title {
  margin-bottom: theme.size(2);

  @include theme.mediaDesktopUp {
    grid-column: 3 / span 8;
    margin-bottom: theme.size(3);
    text-align: center;
  }
}

.subtitle {
  @include theme.mediaDesktopUp {
    grid-column: 2 / span 10;
    text-align: center;
  }
}
