@use 'styles' as theme;

.contentWrapper {
  display: grid;
  gap: theme.size(4);
  grid-template-areas:
    'title'
    'illustration'
    'reasons';

  @include theme.mediaTabletLandscapeUp {
    grid-template-areas:
      'title illustration'
      'reasons illustration';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    gap: theme.size(5 4);
  }

  &.reverse {
    @include theme.mediaTabletLandscapeUp {
      grid-template-areas:
        'illustration title'
        'illustration reasons';
    }
  }
}

.title {
  @include theme.headingL;
  grid-area: title;
  text-align: center;

  @include theme.mediaTabletLandscapeUp {
    @include theme.headingM;
    text-align: left;
    align-self: end;
  }
}

.illustrationWrapper {
  max-height: theme.size(45);
  height: 100%;
  grid-area: illustration;
  margin: theme.size(-1 0 4);

  @include theme.mediaTabletLandscapeUp {
    margin: 0;
  }
}

.illustration {
  @include theme.square(100%);
  color: transparent;
}

.reasons {
  @include theme.unstyledList;
  grid-area: reasons;
}

.iconWrapper {
  @include theme.circle(theme.size(3.5));
  @include theme.flexCenter;

  flex-shrink: 0;
  background-color: theme.$colorBlue;
  margin: theme.size(0.5);
}

.reason {
  @include theme.mediaDesktopUp {
    align-items: center;
  }
}
